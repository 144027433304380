<template>
    <div id="amapDrawPolygon">
        <div class="tool" v-if="!isReadonly">
            <div class="draw" @click="draw" :style="`color: ${operateStatus == 1 ? '#409eff' : ''};`"
                v-if="operateStatus != 2">绘制</div>
            <!-- <div class="edit" @click="editStart" :style="`color: ${operateStatus == 2 ? '#409eff' : ''};`"
                v-if="operateStatus != 2">编辑</div> -->
            <div class="edit" @click="editEnd" :style="`color: ${operateStatus == 2 ? '#409eff' : ''};`"
                v-if="operateStatus == 2">确定</div>
            <div class="clear" @click="clear" v-if="operateStatus != 2">清除</div>
        </div>
        <div id="map"></div>
    </div>
</template>
  
<script>
export default {
    props: {
        center: [],
    },
    data() {
        return {
            map: null,
            mouseTool: null,
            polyEditor: null,
            isComplete: false,
            operateStatus: 0,// 0未操作 1绘制 2编辑
            pathArr: [],
            polygon: null,
            isReadonly: false,
        };
    },
    mounted() {
        this.initMap();
    },
    methods: {
        initMap() {
            this.map = new AMap.Map("map", {
                center: this.center.length ? this.center :[116.39713,39.909479],
              layers: [
                // 卫星
                new AMap.TileLayer.Satellite(),
                // 路网
                new AMap.TileLayer.RoadNet()
              ],
                zoom: 15,
            });
            this.map.plugin(['AMap.MouseTool'], () => {
                this.mouseTool = new AMap.MouseTool(this.map);
                this.mouseTool.on('draw', e => {
                    //console.log(e);
                    this.pathArr = e.obj.De?.path || e.obj._opts?.path;
                    this.isComplete = true;
                    this.operateStatus = 0;
                    this.mouseTool.close();
                    this.$emit('polygonPath', this.dealPath(this.pathArr), this.clacCenterPosition(this.pathArr), '绘制');
                });
            });
        },
        // 绘制
        draw() {
            if (this.pathArr.length) {
                return this.$message.info("已存在地块");
            }
            this.mouseTool.close(true);
            this.operateStatus = 1;
            this.mouseTool.polygon({
                strokeColor: "#409eff",
                strokeWeight: 2,
                strokeOpacity: 1,
                fillOpacity: 0.2,
                fillColor: '#1791fc',
                zIndex: 50,
            });
        },
        // 清除
        clear() {
            this.pathArr = [];
            this.mouseTool.close(true);
            this.map.clearMap();
            this.$emit('polygonPath', this.dealPath(this.pathArr), this.clacCenterPosition(this.pathArr), '清除');
        },
        dealPath(path) {
            if (path.length && !path[0].lng) {
                return path
            }
            let pathArr = [];
            for (const a of path) {
                pathArr.push([a.lng, a.lat]);
            }
            return pathArr;
        },
        // 多边形中心点计算
        clacCenterPosition(path) {
            if (!path.length) return [];
            let lngArr = [], latArr = [], centerLng, centerLat;
            if (!path[0].lng) {
                for (const p of path) {
                    lngArr.push(p[0]);
                    latArr.push(p[1]);
                }
            } else {
                for (const p of path) {
                    lngArr.push(p.lng);
                    latArr.push(p.lat);
                }
            }
            centerLng = (Math.min(...lngArr) + Math.max(...lngArr)) / 2;
            centerLat = (Math.min(...latArr) + Math.max(...latArr)) / 2;
            return [parseFloat(centerLng.toFixed(6)), parseFloat(centerLat.toFixed(6))];
        },
        // 回显
        showPath(path, type) {
            this.pathArr = [...path];
            if (type == 'readonly') {// 查看
                this.isReadonly = true;
            } else {
                this.isReadonly = false;
            }
            var polygon = new AMap.Polygon({
                path,
                strokeColor: "#409eff",
                strokeWeight: 2,
                strokeOpacity: 1,
                fillOpacity: 0.2,
                fillColor: '#1791fc',
                zIndex: 50,
            });
            this.map.add(polygon);
            this.map.setFitView();
        },
    },
};
</script>
<style scoped lang="less">
#amapDrawPolygon {
    position: relative;
    width: calc(100% - 2px);
    height: 35vh;
  min-height:200px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;

    #map {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .tool {
        position: absolute;
        top: 50%;
        right: 0.5vw;
        transform: translateY(-50%);
        line-height: 20px;
        font-size: 12px;
        z-index: 99;

        >div {
            padding: 0 0.5vw;
            border: 1px solid #409eff;
            border-radius: 4px;
            cursor: pointer;
            transition: all 0.3s;
            background-color: #fff;

            &+div {
                margin-top: 10px;
            }

            &:hover {
                color: #409eff;
            }
        }
    }
}
</style>